import styled from 'styled-components'

const Container = styled.div`
  display: flex;
`

const Content = styled.div`
  display: flex;
  position: relative;

  input {
    display: none;
  }
`

const Input = styled.input`
`

const Label = styled.label`
  display: flex;
  gap: 1rem;
  cursor: pointer;
`

const ImgContainer = styled.div<{
  width?: string
  height?: string
  radius?: string
  border?: string
}>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: ${({width}) => (width ? width : '10rem')};
  height: ${({height}) => (height ? height : '10rem')};
  border-radius: ${({radius}) => (radius ? radius : '.4rem')};
  border: ${({border}) => (border ? border : '.1rem solid #efebef')};
  overflow: hidden;
  position: relative;
  
  Svg {
    position: absolute;
    right: .5rem;
    top: .5rem;
    background: #ffffff;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: none;
  }
  
  &:hover {
    Svg {
      display: block;
    }
  }
`

const Img = styled.img`
  width: 100%;
  height: auto;
`

const Text = styled.div`
  background: #f8f8f8;
  border: .1rem solid #efebef;
  border-radius: .8rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: #666666;
  padding: 1rem;
`

const Icon = styled.div`

`

const Add = styled.div<{
  width?: string
  height?: string
  radius?: string
  border?: string
}>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: ${({width}) => (width ? width : '10rem')};
  height: ${({height}) => (height ? height : '10rem')};
  border-radius: ${({radius}) => (radius ? radius : '.4rem')};
  border: ${({border}) => (border ? border : '.1rem solid #efebef')};
`

const Modal = styled.div`
  background: #f8f8f8;
  border: .1rem solid #efebef;
  border-radius: .8rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: #666666;
  padding: 1rem;
`

export const Style = {
  Container,
  Content,
  Input,
  Label,
  ImgContainer,
  Img,
  Text,
  Icon,
  Add,
  Modal
}
