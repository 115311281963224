import styled from 'styled-components'

import Image from '../../module/image'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  position: fixed;
  left:0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999999999;
  width: 100%;
  height: 100%;
  z-index: 9;
`

const Modal = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-self: center;
  text-align: left;
  padding: 0 1rem;
  box-sizing: border-box;
  width: 50%;
  height: 80vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  font-size: 1.2rem;
  text-align: center;
  z-index: 9;
  cursor: pointer;
  
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    cursor: pointer;
  }
`

const Index = (props: any) => {
  return (
    <Container>
      <Modal onClick={() => props.reset()}>
        <Image
          src={props.image}
          width={'100%'}
          height={'auto'}
          radius={'1rem'}
        />
      </Modal>
    </Container>
  )
}
export default Index
