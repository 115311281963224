import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {accountIntAtom, accountSalesAtom} from '../recoil/account'
import moment from 'moment'

export const AccountAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const setInt = useSetRecoilState(accountIntAtom)
  const setSales = useSetRecoilState(accountSalesAtom)

  const getAccountList = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/account/partnerList',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setInt(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getSalesList = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/account/saleList',
      params: params,
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setSales(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getAccountExcel = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/account/getAccountExcelList',
      params: params,
      responseType: 'blob'
    }).then(
      response => {
        loader({
          isLoading: false
        })
        const blob = new Blob([response.data])
        // const blob = new Blob([this.content], {type: 'text/plain'})
        const fileObjectUrl = window.URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = fileObjectUrl
        link.style.display = 'none'
        link.download = moment().format('YYYYMMDD') + '_account.xlsx'
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(fileObjectUrl)
      },
      error => {
        console.log(error)
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getSalesExcel = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/account/getSalesExcelList',
      params: params,
      responseType: 'blob'
    }).then(
      response => {
        loader({
          isLoading: false
        })
        const blob = new Blob([response.data])
        // const blob = new Blob([this.content], {type: 'text/plain'})
        const fileObjectUrl = window.URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = fileObjectUrl
        link.style.display = 'none'
        link.download = moment().format('YYYYMMDD') + '_sales.xlsx'
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(fileObjectUrl)
      },
      error => {
        console.log(error)
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    getAccountList,
    getSalesList,
    getAccountExcel,
    getSalesExcel
  }
}
