import React, {useState, useEffect, useRef} from 'react'
import {useParams} from 'react-router'
import InputField from '../../module/input'
import Select from '../../module/select'
import styled from 'styled-components'
import Location from '../../module/location'
import Button from '../../module/button'
import Modal from './Modal'

import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {alertAtom} from '../../store/recoil/alert'
import {authAtom} from '../../store/recoil/auth'

import {SalesAction} from '../../store/action'
import {PartnerAction} from '../../store/action'
import {partnerAtom, partnerDetailAtom} from '../../store/recoil/partner'
import {salesDetailAtom} from '../../store/recoil/sales'
import File from '../../module/file'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 9.7rem;
`

const Section = styled.div`
  display: flex;
  flex: 1;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 50%;
  align-self: stretch;
  flex-grow: 1;

  @media (max-width: 1008px) {
    max-width: 100%;
  }
`

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 2rem 0;
`

const Action = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 2rem;
`

const Files = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4rem;
  align-self: baseline;
`

const FileSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const Title = styled.div`
  display: flex;
  flex: 1;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 1rem 0;
  white-space: nowrap;
`

const Index = () => {
  const params= useParams()
  const setAlert = useSetRecoilState(alertAtom)
  const {regis, getDetail, deleteSales} = SalesAction()
  const { getPartnerList } = PartnerAction()
  const partner = useRecoilValue(partnerAtom)
  const [state, setState] = useState<any>({})
  const [detail, setDetail] = useRecoilState(salesDetailAtom)
  const auth = useRecoilValue(authAtom)
  const [modal, setModal] = useState<any>(null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e: any) => {
    if (e) {
      e.preventDefault()
    }

    const {
      partner_id,
      user_email,
      user_password,
      user_name,
      sales_jumin,
      sales_address,
      sales_stock,
      sales_phone_0
    } = state

    if (!partner_id) {
      setAlert({
        title: '오류',
        type: 'alert',
        message: '지사를 선택하세요.',
        isShow: true
      })
      return
    }

    if (!user_email) {
      setAlert({
        title: '오류',
        type: 'alert',
        message: '이메일을 입력하세요.',
        isShow: true
      })
      return
    }

    if (!params.id && !user_password) {
      setAlert({
        title: '오류',
        type: 'alert',
        message: '비밀번호를 입력하세요.',
        isShow: true
      })
      return
    }

    if (!user_name) {
      setAlert({
        title: '오류',
        type: 'alert',
        message: '이름을 입력하세요.',
        isShow: true
      })
      return
    }

    if (!sales_jumin) {
      setAlert({
        title: '오류',
        type: 'alert',
        message: '주민번호를 입력하세요.',
        isShow: true
      })
      return
    }

    if (!sales_stock) {
      setAlert({
        title: '오류',
        type: 'alert',
        message: '증권번호를 입력하세요.',
        isShow: true
      })
      return
    }

    if (!sales_stock) {
      setAlert({
        title: '오류',
        type: 'alert',
        message: '증권번호를 입력하세요.',
        isShow: true
      })
      return
    }

    if (!sales_phone_0) {
      setAlert({
        title: '오류',
        type: 'alert',
        message: '연락처를 입력하세요.',
        isShow: true
      })
      return
    }

    if (!sales_address) {
      setAlert({
        title: '오류',
        type: 'alert',
        message: '주소를 입력하세요.',
        isShow: true
      })
      return
    }

    setState({
      ...state,
      submitted: true
    })

    regis(state)
  }

  useEffect(() => {
    getPartnerList()
    params.id && getDetail(Number(params.id))

    return () => {
      setDetail(null)
    }
  }, [])

  useEffect(() => {
    setState({
      ...state,
      user_email: detail?.user_email,
      partner_id: detail?.partner_id,
      role: auth.role,
      partner_name: detail?.partner_name,
      user_id: detail?.user_id ? detail?.user_id : '',
      user_name: detail?.user_name,
      user_phone: detail?.user_phone,
      sales_account: detail?.sales_account,
      sales_address: detail?.sales_address,
      sales_id: detail?.sales_id,
      sales_jumin: detail?.sales_jumin,
      sales_phone_0: detail?.sales_phone_0,
      sales_phone_1: detail?.sales_phone_1,
      sales_stock: detail?.sales_stock,
      files: detail?.sales_files ? detail?.sales_files : []
    })
  }, [detail])

  return (
    <Container>
      <Location location={{name: `영업자  ${params.id ? '수정' : '등록'}`}}/>

      <Content>
        <Section>
          <Select
            id="type"
            name="type"
            title={'선택'}
            size={'lg'}
            config={
              {
                title: 'partner_name',
                value: 'partner_id'
              }
            }
            isSelected={state.partner_id}
            value={partner}
            // onChange={(e) => setDetail({
            //   ...detail,
            //   partner_id: e.partner_id
            // })}
            onChange={(e) => setState({
              ...state,
              partner_id: e.partner_id
            })}
            required={true}
            readOnly={false}
            disabled={false}
          />
        </Section>

        <Section>
          <InputField
            useAnimation={true}
            type={'text'}
            id="user_email"
            name="user_email"
            title="이메일"
            placeholder="이메일"
            value={state.user_email}
            onChange={(e) => handleChange(e)}
            disabled={params.id ? true : false}
            required={true}
          />

          <InputField
            useAnimation={true}
            type={'text'}
            id="user_password"
            name="user_password"
            title="비밀번호"
            placeholder="비밀번호"
            onChange={(e) => handleChange(e)}
            required={true}
          />
        </Section>

        <Section>
          <InputField
            useAnimation={true}
            type={'text'}
            id="user_name"
            name="user_name"
            title="이름"
            placeholder="이름"
            value={state.user_name}
            onChange={(e) => handleChange(e)}
            required={true}
          />

          <InputField
            useAnimation={true}
            type={'text'}
            id="sales_jumin"
            name="sales_jumin"
            title="주민번호"
            className="form-control input-text"
            placeholder="주민번호"
            value={state.sales_jumin}
            onChange={(e) => handleChange(e)}
            required={true}
          />
        </Section>

        <Section>
          <InputField
            useAnimation={true}
            type={'text'}
            id="sales_address"
            name="sales_address"
            title="주소"
            placeholder="주소"
            value={state.sales_address}
            onChange={(e) => handleChange(e)}
            required={true}
          />
        </Section>

        <Section>
          <InputField
            useAnimation={true}
            type={'text'}
            id="sales_stock"
            name="sales_stock"
            title="보증보험 증권번호"
            placeholder="보증보험 증권번호"
            value={state.sales_stock}
            onChange={(e) => handleChange(e)}
            required={true}
          />

          <InputField
            useAnimation={true}
            type={'text'}
            id="sales_account"
            name="sales_account"
            title="계좌번호"
            placeholder="계좌번호"
            value={state.sales_account}
            onChange={(e) => handleChange(e)}
            required={true}
          />
        </Section>

        <Section>
          <InputField
            useAnimation={true}
            type={'text'}
            id="sales_phone_0"
            name="sales_phone_0"
            title="휴대폰번호 1"
            placeholder="휴대폰번호 1"
            value={state.sales_phone_0}
            onChange={(e) => handleChange(e)}
            required={true}
          />

          <InputField
            useAnimation={true}
            type={'text'}
            id="sales_phone_1"
            name="sales_phone_1"
            title="휴대폰번호 2"
            placeholder="휴대폰번호 2"
            value={state.sales_phone_1}
            onChange={(e) => handleChange(e)}
          />
        </Section>
      </Content>

      <Files>
        <FileSection>
          <File
            id={'file'}
            name={'file'}
            placeholder={'첨부파일 찾아보기'}
            width={'10rem'}
            height={'10rem'}
            radius={'1rem'}
            border={'.1rem solid #efebef'}
            onChange={(files: any) => setState((prevState: any) => ({
              ...prevState,
              files: [...Array.from(files.files), ...prevState.files]
             }))}
            thumbnail={state.files && state.files.length > 0 ? state.files : ''}
            setImage={(image: any) => setModal(image)}
          />
        </FileSection>
      </Files>

      <Action>
        <Button
          size={'md'}
          margin={'0 1rem 0 0'}
          padding={'1rem 5rem'}
          radius={'.5rem'}
          bgcolor={'#3f3b3f'}
          color={'#ffffff'}
          themecolor={'dark-gray'}
          disabled={false}
          // onClick={handleSubmit}

          onClick={() => setAlert({
            title: '',
            type: 'confirm',
            message: `${params.id ? '수정하시겠습니까?' : '등록하시겠습니까?'}`,
            isShow: true,
            action: {
              method: (e: any) => handleSubmit(e)
            }
          })}

        >
          {params.id ? '수정' : '등록'}
        </Button>

        {params.id &&
          <Button
            size={'md'}
            margin={'0 0 0 0'}
            padding={'1rem 5rem'}
            radius={'.5rem'}
            bgcolor={'#3f3b3f'}
            color={'#ffffff'}
            themecolor={'red'}
            disabled={false}
            onClick={() => setAlert({
              title: '',
              type: 'confirm',
              message: '삭제하시겠습니까?',
              isShow: true,
              action: {
                method: () => {
                  deleteSales({
                    user_id: detail?.user_id,
                    sales_id: detail?.sales_id
                  })
                }
              }
            })}
          >
            삭제
          </Button>
        }
      </Action>
      {modal &&
          <Modal
            image={modal ? modal: ''}
            reset={() => setModal(null)}
          />
      }
    </Container>

  )
}

export default Index
