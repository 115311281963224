import {createGlobalStyle} from 'styled-components'

export const Style = createGlobalStyle`
  .DateRangePicker_picker {
    z-index: 9 !important;
  }

  .DateRangePickerInput {
    display: flex !important;
    flex: 1;
    flex-grow: 0;
    justify-content: center;
    align-items: center;
  }

  .DateRangePickerInput__withBorder {
    border: .1rem solid #efebef !important;
    border-radius: .8rem !important;
    overflow: hidden;
    padding: 0 0;
    background: #f8f8f8 !important;
  }

  .DateInput {
    display: flex !important;
    flex: 1;
    position: relative;
    width: unset !important;
    justify-content: center;
    align-items: center;
    font-weight: 200;
    font-size: unset !important;
    line-height: unset !important;
    color: #333333 !important;
    background-color: #f8f8f8 !important;
    width: unset !important;
    padding: unset !important;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: usnet !important;
    border-left: 0;
    border-radius: 0;
    max-width: 10rem !important;
    padding: 1.35rem 0 !important;
  }

  .DateInput_input {
    text-align: center !important;
    background-color: #f8f8f8 !important;
    font-size: 1.5rem !important;
    font-weight: bold !important;
    border-bottom: unset !important;
    padding: unset !important;
    line-height: unset !important;
    width: unset !important;
    margin: unset !important;
    max-width: 10rem !important;
  }

  .DateInput_input__focused {
    border-bottom: unset !important;
  }

  // 오른쪽 구석의 화살표를 안보이게 한다.
  .DayPickerKeyboardShortcuts_buttonReset {
    display: none;
  }

  // 달력 각 칸의 기본 스타일.
  .CalendarDay__default {
    border: none !important;
    border-radius: 50%;
    vertical-align: middle;
    outline: none;
  }

  // 달력 각 칸에 호버가 되었을 때 스타일
  .CalendarDay__default:hover {
    background: transparent;
    border: none !important;
    color: black;
    box-shadow: inset 0 0 0 1px black;
  }

  // 체크인 체크아웃이 선택되었을 때 그 사의 날짜들에 대한 스타일
  .CalendarDay__selected_span {
    background-color: #f7f7f7;
    border: none;
    color: black;
  }

  // 체크인 체크아웃이 선택되었을 때 그 사의 날짜들에 호버 혹은 클릭했을 시 스타일
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    color: black;
    background-color: #f7f7f7;
  }

  // 선택된 체크인 체크아웃 날짜에 대한 스타일
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: black;
    border: none;
    color: white;
  }

  // 블록된 날짜에 대한 스타일링
  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar:active,
  .CalendarDay__blocked_calendar:hover {
    background: white;
    border: none;
    color: #d2d2d2;
    box-shadow: none;
    text-decoration: line-through;
  }

  // 선택될 범위에 대한 스타일링
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    color: black;
    background-color: #f7f7f7;
    border: none;
  }

  // 요일 표시 부분에 대한 스타일.
  .CalendarMonth_caption {
    margin-bottom: 10px;
  }

  .CalendarDay__default {
    border: unset !important;
  }
`
