import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Common} from '../../util/common'
import Svg from '../svg/'

const Container = styled.div<{ $useAnimation?: boolean }>`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: ${({$useAnimation}) => ($useAnimation ? '.8rem 0 .8rem 0' : '0 0 0 0')};
`

const Label = styled.label<{ $isActive?: boolean }>`
  display: flex;
  font-size: 1.4rem;
  font-weight: 400;
  color: #B4B4B4FF;
  position: absolute;
  transform: translate(1rem, 0) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  transform: ${({$isActive}) => ($isActive ? 'translate(1rem, -40%) scale(.70)' : '')};
`

const Length = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 1.2rem;
  margin-left: .2rem;
`

const Required = styled.span`
  display: flex;
  flex: 1;
  align-items: self-start;
  font-size: 1.4rem;
  margin-right: .2rem;
`

const Input = styled.input<{
  align?: string,
  $useAnimation?: boolean,
  $isActive?: boolean
}>`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  border: .1rem solid #efebef;
  border-radius: .8rem;
  //background: #f8f8f8;
  background: ${({ disabled }) => (disabled ? '#ececec' : '#f8f8f8')};
  font-size: 1.4rem;
  //color: #333333;
  color: ${({ disabled }) => (disabled ? '#999999' : '#333333')};
  text-align: ${({align}) => (align ? align : '')};
  padding: ${({
                $useAnimation,
                $isActive
              }) => ($useAnimation ? ($isActive ? '2.2rem 1rem .6rem 1rem' : '1.4rem 1rem 1.4rem 1rem') : '1rem')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
`

const Validation = styled.div<{ $isCheck?: boolean, $isChecked?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 100%;
  top: 50%;
  right: 2rem;
  transform: translate(0, -50%) !important;
  position: absolute;
  background: ${({$isCheck, $isChecked}) => ($isCheck ? '#3386C2' : ($isChecked ? '#3386C2' : '#efebef'))};
  border: .1rem solid ${({$isCheck, $isChecked}) => ($isCheck ? '#3386C2' : ($isChecked ? '#3386C2' : '#efebef'))};

  i {
    background-image: url("../../images/icon-check-white.png");
    background-size: 1.2rem;
    width: 1.2rem;
    height: 1.2rem;
  }
`

interface InputProps {
  align?: string
  useAnimation?: boolean
  type?: string
  numberType?: string
  id?: string
  name?: string
  title?: string
  className?: string
  placeholder?: string
  required?: boolean
  autoFocus?: boolean
  value?: string
  check?: boolean
  minLength?: number
  maxLength?: number
  isChecked?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

const Index = ({
                 id,
                 name,
                 align,
                 useAnimation,
                 type,
                 numberType,
                 placeholder,
                 value,
                 check,
                 minLength,
                 maxLength,
                 isChecked,
                 required,
                 onChange,
                 disabled,
               }: InputProps) => {
  const [text, setText] = useState<string>('')
  const [isActive, setIsActive] = useState<boolean>(false)
  const [isCheck, setIsCheck] = useState<boolean>(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (check) {
      if (id === 'email') {
        setIsCheck(Common.isEmail(e.target.value) ? true : false)
      }

      if (minLength) {
        setIsCheck(Common.isMinLength(e.target.value, minLength) ? true : false)
      }
    }

    if (numberType && Common.isNumber(e.target.value.replaceAll(',', ''))) {
      return
    }

    setIsActive(e.target.value !== '' ? true : false)
    setText(numberType === 'price' ? Common.toString(e.target.value) : e.target.value)
    onChange?.(e)
  }

  useEffect(() => {
    value && setText(value)
    value && setIsActive(true)
  }, [value])

  return (
    <Container $useAnimation={useAnimation}>
      <Input
        align={align}
        $useAnimation={useAnimation}
        $isActive={isActive}
        id={id}
        name={name}
        type={type}
        placeholder={(!useAnimation ? placeholder : '')}
        value={text}
        onChange={handleChange}
        // maxLength={numberType !== 'price' ? maxLength : numberType === 'price' ? 16 : 999999999}
        maxLength={maxLength}
        autoComplete="off"
        disabled={disabled}
      />
      {
        useAnimation &&
          <Label
              htmlFor={id}
              $isActive={isActive}
          >
            {required && <Required>* </Required>}
            {placeholder}
              <Length>
                {minLength && !maxLength && ` (최소 ${minLength}자)`}
                {maxLength && !minLength && ` (최대 ${maxLength}자)`}
                {minLength && maxLength && ` (${minLength} ~ ${maxLength}자)`}
              </Length>
          </Label>
      }
      {
        check &&
          <Validation
              $isCheck={isCheck}
              $isChecked={isChecked}
          >
              <Svg
                  icon="check"
                  width={24}
                  height={24}
                  fill={isCheck ? '#3386C2' : isChecked ? '#3386C2' : '#efebef'}
                  color={isCheck ? '#3386C2' : isChecked ? '#3386C2' : '#efebef'}
                  stroke={isCheck ? '#ffffff' : isChecked ? '#ffffff' : '#ffffff'}
                  strokeWidth={2}
                  strokeCap={'round'}
                  strokeJoin={'round'}
              />
          </Validation>
      }
    </Container>
  )
}

export default Index
