import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {salesAtom, salesDetailAtom} from '../recoil/sales'

export const SalesAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const setSales = useSetRecoilState(salesAtom)
  const setSalesDetail = useSetRecoilState(salesDetailAtom)

  const regis = (params: any) => {

    loader({
      isLoading: true
    })

    const formData = new FormData()
    formData.append('user_id', params.user_id)
    formData.append('partner_id', params.partner_id)
    formData.append('role', params.role)
    formData.append('partner_name', params.partner_name)
    formData.append('user_email', params.user_email)

    formData.append('user_password', params.user_password)
    formData.append('user_name', params.user_name)
    formData.append('sales_id', params.sales_id)
    formData.append('sales_jumin', params.sales_jumin)
    formData.append('user_phone', params.user_phone)
    formData.append('sales_address', params.sales_address)
    formData.append('sales_stock', params.sales_stock)
    formData.append('sales_account', params.sales_account)
    formData.append('sales_phone_0', params.sales_phone_0)
    formData.append('sales_phone_1', params.sales_phone_1)
    params.files.forEach((file: any) => {
      if(file instanceof File) {
        formData.append('file', file)
      }
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/sales/regis',
      params: formData
    }).then(
      response => {
        loader({
          isLoading: false
        })

        if(params.user_id) {
          setAlert({
            title: '확인',
            type: 'alert',
            message: '수정되었습니다.',
            isShow: true
          })
          return
        }

        if(response.data.status !== 200) {
          setAlert({
            title: '오류',
            type: 'alert',
            message: '이미 등록된 이메일입니다.',
            isShow: true
          })
          return
        }

        navigate('/sales')
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getSalesList = () => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/sales/getList',
      params: ''
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setSales(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getDetail = (params: number) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/sales/getDetail',
      params: {
        id: params
      }
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setSalesDetail(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deleteSales = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'delete',
      endPoint: '/sales/deleteSales',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        if(response.data.status === 200) {
          setAlert({
            title: '확인',
            type: 'alert',
            message: '삭제되었습니다.',
            isShow: true
          })

          navigate('/sales')
        }
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    regis,
    getSalesList,
    getDetail,
    deleteSales
  }
}
