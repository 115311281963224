import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const HiddenCheckbox = styled.input`
  display: none;
`

const CheckBox = styled.label<{checked?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: 0;
  outline: 0;
  flex-grow: 0;
  border-radius: 50%;
  transition: background 300ms;
  cursor: pointer;
  
  background: ${({checked}) => (checked ? '#3386c2' : `#ffffff`)};
  background-image: ${({checked}) => (checked ? 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'22\' height=\'22\' viewBox=\'0 0 26 26\'%3E %3Cpath d=\'M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z\' fill=\'%23fff\'/%3E %3C/svg%3E")' : `#ffffff`)};
  box-shadow: ${({checked}) => (checked ? 'none' : `inset 0 0 0 .1rem #CCD3D8`)};
}
`

const Label = styled.label`
  display: flex;
  font-size: 1.2rem;
  margin-left: .5rem;
  cursor: pointer;
`

interface CheckboxProps {
  id: string
  checked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  label?: string | null
}

const Checkbox = ({id, checked, onChange, label}: CheckboxProps) => (
  <Container>
    <HiddenCheckbox
      id={id}
      type="checkbox"
      onChange={onChange}
      checked={checked}
    />
    <CheckBox
      checked={checked}
      htmlFor={id}
    >
    </CheckBox>
    {label ? <Label htmlFor={id}>{label}</Label> : null}
  </Container>
)

export default Checkbox
