import {useNavigate} from 'react-router-dom'
import {defaultService} from '../../../store/service'
import {loaderAtom} from '../../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable} from 'recoil'
import {alertAtom} from '../../../store/recoil/alert'

export const FileAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)

  const deleteFile = (params: any, cb?: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/file/deleteFile',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })
        cb && cb()
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    deleteFile
  }
}
