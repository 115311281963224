import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {authAtom} from '../recoil/auth'

export const AuthAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const setAuth = useSetRecoilState(authAtom)

  const authLogin = (email: string, password: string) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/auth/login',
      params: {email: email, password: password}
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setAuth(response.data.payload)
        navigate('/')
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: '오류',
          type: 'alert',
          message: error.response.data.message,
          isShow: true
        })
      }
    )
  }

  const authLogout = (params: any) => {
    defaultService.handleService({
      method: 'post',
      endPoint: '/auth/logout',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        setAuth('')
        navigate('/auth')
      },
      error => {
        loader({
          isLoading: false
        })

        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    authLogin,
    authLogout
  }
}
