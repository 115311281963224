import React, {useState} from 'react'
import {Link, NavLink, useLocation} from 'react-router-dom'
import styled, {css} from 'styled-components'
import Svg from '../../module/svg/'
import {authAtom} from '../../store/recoil/auth'
import {useRecoilValue} from 'recoil';

const NavContainer = styled.div`
  display: flex;
  flex: 1;
  min-width: 3.7rem;
  min-height: 9.7rem;
  position: relative;
`

const Nav = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 10rem;
  position: sticky;
  top: 2.5rem;
`

const NavItem = styled.ul`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 1.4rem;
  margin: 0 0 1rem 0;
  padding: .2rem 0 .2rem 0;
  border-radius: 1.2rem;
`

const NavItemLabel = styled.ul<{ selected: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1.7rem;
  padding: 0 0 0 0;
  border-radius: 1.2rem;
  cursor: pointer;

  svg {
    transform: ${({selected}) => (selected ? 'rotate(-90deg)' : 'rotate(90deg);')};
  }
`

const NavLinkItemLabel = styled(NavLink)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1.7rem;
  padding: 0 0 0 0;
  border-radius: 1.2rem;
`

const NavSubItem = styled.ul<{ selected: boolean }>`
  font-weight: 400;
  font-size: 1.4rem;
  overflow: ${({selected}) => (selected ? 'hidden' : 'auto')};
  height: ${({selected}) => (selected ? '0' : 'auto')};
  padding: ${({selected}) => (selected ? '0' : '1rem 0 1rem 0')};
  transition: all 0.3s;
`

const NavSubItemLabel = styled(NavLink)`
  display: flex;
  flex: 1;
  flex-direction: column;
  font-weight: 400;
  font-size: 1.4rem;
  padding: .4rem 0rem;
  color: #666666;

  &.active {
    font-weight: bold;
  }
`

const Index = () => {

  const auth = useRecoilValue(authAtom)

  const [menu, setMenu] = useState({
    m0: false,
    m1: false,
    m2: false,
    m3: false
  })

  return (
    <NavContainer>
      <Nav>
        {
          auth.role === 0 &&
            <NavItem>
                <NavItemLabel
                    selected={menu.m0}
                    onClick={() => setMenu({
                      ...menu,
                      m0: !menu.m0
                    })}
                >
                    지사관리
                    <Svg
                        icon={'arrow'}
                        width={12}
                        height={12}
                        color={'#666666'}
                    />
                </NavItemLabel>
                <NavSubItem selected={menu.m0}>
                    <NavSubItemLabel to="/partner" end>
                        지사관리
                    </NavSubItemLabel>
                    <NavSubItemLabel to="/partner/regis">
                        지사등록
                    </NavSubItemLabel>
                </NavSubItem>
            </NavItem>
        }

        {
          (auth.role === 0 || auth.role === 1) &&

            <NavItem>
                <NavItemLabel
                    selected={menu.m1}
                    onClick={() => setMenu({
                      ...menu,
                      m1: !menu.m1
                    })}
                >
                    영업자 관리
                    <Svg
                        icon={'arrow'}
                        width={12}
                        height={12}
                        color={'#666666'}
                    />
                </NavItemLabel>
                <NavSubItem selected={menu.m1}>
                    <NavSubItemLabel to="/sales" end>
                        영업자 관리
                    </NavSubItemLabel>
                    <NavSubItemLabel to="/sales/regis">
                        영업자 등록
                    </NavSubItemLabel>
                </NavSubItem>
            </NavItem>
        }

        <NavItem>
          <NavItemLabel
            selected={menu.m2}
            onClick={() => setMenu({
              ...menu,
              m2: !menu.m2
            })}
          >
            정산 관리
            <Svg
              icon={'arrow'}
              width={12}
              height={12}
              color={'#666666'}
            />
          </NavItemLabel>
          <NavSubItem selected={menu.m2}>
            {
              (auth.role === 1 || auth.role === 0) &&
              <NavSubItemLabel to="/account/int">
                지사 정산 관리
              </NavSubItemLabel>
            }
            <NavSubItemLabel to="/account/sales">
              영업자 정산 관리
            </NavSubItemLabel>

          </NavSubItem>
        </NavItem>

        <NavItem>
          <NavItemLabel
            selected={menu.m3}
            onClick={() => setMenu({
              ...menu,
              m3: !menu.m3
            })}
          >
            계약 관리
            <Svg
              icon={'arrow'}
              width={12}
              height={12}
              color={'#666666'}
            />
          </NavItemLabel>
          <NavSubItem selected={menu.m3}>
            {/*<NavSubItemLabel to="/contract">*/}
            {/*  계약서 등록*/}
            {/*</NavSubItemLabel>*/}
            <NavSubItemLabel to="/contract">
              계약서 관리
            </NavSubItemLabel>
          </NavSubItem>
        </NavItem>

        {
          auth.role === 0 &&
            <>
                <NavItem>
                    <NavLinkItemLabel to="/board">
                        공지사항
                    </NavLinkItemLabel>
                </NavItem>
                {/*<NavItem>*/}
                {/*    <NavLinkItemLabel to="/pds">*/}
                {/*        자료실*/}
                {/*    </NavLinkItemLabel>*/}
                {/*</NavItem>*/}
                <NavItem>
                    <NavLinkItemLabel to="/setting">
                        설정
                    </NavLinkItemLabel>
                </NavItem>
            </>
        }

      </Nav>
    </NavContainer>
  )
};

export default Index
