import React, {useState, useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import Location from '../../module/location'
import Table from '../../module/table'
import InputField from '../../module/input'

import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {settingAtom} from '../../store/recoil/setting'
import {SettingAction} from '../../store/action'
import Button from '../../module/button'
import {alertAtom} from '../../store/recoil/alert'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 9.7rem;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 2rem 0;
`

const Section = styled.div`
  display: flex;
  flex: 1;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 50%;
  align-self: stretch;
  flex-grow: 1;
  margin-Bottom: 2rem;

  @media (max-width: 1008px) {
    max-width: 100%;
  }
`

const Title = styled.div`
  display: flex;
  flex: 1;
  font-size: 1.3rem;
  font-weight: 400;
  color: #666666;
`

const Action = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 2rem;
`

const Index = () => {
  const setAlert = useSetRecoilState(alertAtom)
  const [setting, setSetting] = useRecoilState(settingAtom)
  const {regis, getSetting} = SettingAction()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSetting({
      ...setting,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e: any) => {
    if (e) {
      e.preventDefault()
    }
    // console.log(state)
    // const {email, password} = state
    // setState({
    //   ...state,
    //   submitted: true
    // })

    // if (email && password) {
    regis(setting)
    // }
  }

  useEffect(() => {
    getSetting()
  }, [])

  return (
    <Container>
      <Location location={{name: '설정'}}/>

      <Content>
        <Title>이자율 관리 (%)</Title>
        <Section>
          <InputField
            useAnimation={true}
            type={'text'}
            id="contract_int"
            name="contract_int"
            title="이자율(%)"
            placeholder="이자율(%)"
            value={setting?.contract_int}
            onChange={(e) => handleChange(e)}
          />
        </Section>

        <Title>이자수수료 지급관리 (%)</Title>
        <Section>
          <InputField
            useAnimation={true}
            type={'text'}
            id="int_head_int"
            name="int_head_int"
            title="본사 이자율(%)"
            placeholder="본사 이자율(%)"
            value={setting?.int_head_int}
            onChange={(e) => handleChange(e)}
          />

          <InputField
            useAnimation={true}
            type={'text'}
            id="int_branch_int"
            name="int_branch_int"
            title="지사 이자율(%)"
            placeholder="지사 이자율(%)"
            value={setting?.int_branch_int}
            onChange={(e) => handleChange(e)}
          />

          <InputField
            useAnimation={true}
            type={'text'}
            id="int_sale_int"
            name="int_sale_int"
            title="영업자 이자율(%)"
            placeholder="영업자 이자율(%)"
            value={setting?.int_sale_int}
            onChange={(e) => handleChange(e)}
          />
        </Section>

        <Title>유질 지급관리 (%)</Title>
        <Section>
          <InputField
            useAnimation={true}
            type={'text'}
            id="sale_head_int"
            name="sale_head_int"
            title="본사 이자율(%)"
            placeholder="본사 이자율(%)"
            value={setting?.sale_head_int}
            onChange={(e) => handleChange(e)}
          />

          <InputField
            useAnimation={true}
            type={'text'}
            id="sale_branch_int"
            name="sale_branch_int"
            title="지사 이자율(%)"
            placeholder="지사 이자율(%)"
            value={setting?.sale_branch_int}
            onChange={(e) => handleChange(e)}
          />

          <InputField
            useAnimation={true}
            type={'text'}
            id="sale_sale_int"
            name="sale_sale_int"
            title="영업자 이자율(%)"
            placeholder="영업자 이자율(%)"
            value={setting?.sale_sale_int}
            onChange={(e) => handleChange(e)}
          />
        </Section>
      </Content>

      <Action>
        <Button
          size={'md'}
          margin={'0 1rem 0 0'}
          padding={'1rem 5rem'}
          radius={'.5rem'}
          bgcolor={'#3f3b3f'}
          color={'#ffffff'}
          themecolor={'dark-gray'}
          disabled={false}
          onClick={handleSubmit}
        >
          수정
        </Button>
      </Action>
    </Container>
  )
}

export default Index
