import {atom} from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
  key: 'persist_root',
  storage: localStorage,
})

const salesAtom = atom({
  key: 'salesAtom',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

const salesDetailAtom = atom({
  key: 'salesDetailAtom',
  default: {},
  effects_UNSTABLE: [persistAtom],
})
export {
  salesAtom,
  salesDetailAtom
}
