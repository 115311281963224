import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {settingAtom} from '../recoil/setting'

export const SettingAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const setSetting = useSetRecoilState(settingAtom)

  const regis = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/setting/regis',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        if(response.data.status === 200) {
          setAlert({
            title: '확인',
            type: 'alert',
            message: '수정되었습니다.',
            isShow: true
          })
          return
        }
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getSetting = () => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/setting/getSetting',
      params: ''
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setSetting(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    regis,
    getSetting
  }
}
