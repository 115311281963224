import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import Location from '../../module/location'
import InputField from '../../module/input'
import Select from '../../module/select'
import Button from '../../module/button'
import Pagination from '../../module/pagination'
import {useRecoilValue} from 'recoil'
import {partnerAtom} from '../../store/recoil/partner'
import {salesAtom} from '../../store/recoil/sales'
import {authAtom} from '../../store/recoil/auth'
import {ContractAction, PartnerAction, SalesAction} from '../../store/action'
import History from './History'

import moment from 'moment'
import {useNavigate} from 'react-router-dom'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  min-height: -webkit-fill-available;
`

const Section = styled.div`
  display: flex;
  flex: 1;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 50%;
  align-self: stretch;
  flex-grow: 0;

  @media (max-width: 1008px) {
    max-width: 100%;
  }
`

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 0;
  flex-direction: column;
  margin: 2rem 0;
`

const Action = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 2rem;
  flex-grow: 0;
`

const Nodata = styled.div`
  display: flex;
  flex: 1;
  font-size: 1.3rem;
  font-weight: 400;
  color: #666666;
`

const Contract = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  overflow: auto;
  position: relative;
`

const TableContainer = styled.div`
  display: flex;
  //flex: 1;
  flex-direction: column;
  align-self: stretch;
  overflow: auto;
  position: relative;
`

const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
`

const TableWrapper = styled.div`
  position: relative;
  overflow-x: auto; /* 가로 스크롤을 표시하기 위해 */
`

const Table = styled.table`
  position: absolute;
  width: 100%;
  border-radius: 1rem;
  border-collapse: collapse;
  //overflow: hidden;
  margin-top: 2rem;

  thead {
    border-bottom: .1rem solid #efebef;
  }

  tr: hover {
    background: #fdfdfd;
    cursor: pointer;
  } 
  
  thead th {
    padding: 1.4rem 1.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  } 
  
  th, 
  td {
    font-size: 1.4rem;
    font-weight: 400;
    color: #9e9e9e;
    border-bottom: .1rem solid #efebef;
    padding: 1.4rem 1.4rem;
    overflow: hidden;
  }

  th.name,
  td.name {
    position: sticky;
    top: 0;
    left: 0;
    min-width: 25rem;
    max-width: 25rem;
    white-space: normal;
    z-index: 1;
    background: #ffffff;
  }
  
  td.first {
    //width: 30rem;
    //min-width: 30rem;
    //white-space: nowrap;
  }
}

td:not(.first) {
  white-space: nowrap;
}
`

interface SaleItem {
  created_at?: string
  partner?: string
  partner_id?: number
  user_id?: number
  user_name?: string
  user_phone?: string
}

const Index = () => {
  const navigate = useNavigate()
  const tableRef = useRef<HTMLTableElement>(null)
  const [template, setTemplate] = useState<any>({})
  const [participant1, setParticipant1] = useState<any>({})
  const [participant2, setParticipant2] = useState<any>({})
  const [contract, setContract] = useState<any>({})
  const [modal, setModal] = useState<any>(null)
  const [pageInfo, setPageInfo] = useState<any>({
    size: 5,
    page: 0
  })
  const [documents, setDocuments] = useState<any>({
    count: 0,
    documents: []
  })
  const partner = useRecoilValue(partnerAtom)
  const sale = useRecoilValue(salesAtom)
  const user = useRecoilValue(authAtom)
  const [contractList, setContractList] = useState<any>([])
  const {addContract, getContractList, requestModuSign, getModuSignList, updateContract} = ContractAction()
  const {getPartnerList} = PartnerAction()
  const {getSalesList} = SalesAction()

  useEffect(() => {
    if (tableRef.current) {
      const parentElement = tableRef.current.parentElement
      if (parentElement) {
        const tableHeight = tableRef.current.offsetHeight
        parentElement.style.height = `${tableHeight + 20}px`
      }
    }
  }, [contractList])

  useEffect(() => {
    getPartnerList()
    getSalesList()
    getListData(pageInfo.page, pageInfo.size)

    user.role === 2 && setContract({
      ...contract,
      partner_id: partner[0].partner_id,
      sales_id: user.id
    })

  }, [])

  useEffect(() => {
  }, [contractList])

  const handleUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setParticipant1({
      ...participant1,
      [e.target.name]: e.target.value
    })
  }

  const handlePartnerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setParticipant2({
      ...participant2,
      [e.target.name]: e.target.value
    })
  }

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>, idx: any) => {
    console.log(e.target.name, e.target.value, idx)

    let item = contractList[idx]
    console.log('########## item:: ', item)
    setContractList([
      ...contractList.slice(0, idx),
      {
        ...item,
        value: {
          ...(item?.value),
          [e.target.name]: e.target.value
        }
      },
      ...contractList.slice(idx + 1, contractList.length)
    ])
  }

  const getListData = (page: number, size: number) => {

    let keyword = ''
    //let keyword = `p${user.parent}u${user.id}`
    if (user.role === 2) keyword = `p${user.parent}u${user.id}`
    if (user.role === 1) keyword = `p${user.parent}u`

    getModuSignList({
      page: page,
      size: size
    }, keyword, (cb: any) => {

      setDocuments(cb)

      getContractList(cb?.documents?.map((doc: any) => doc.id), (cb: any) => {
        setContractList(cb?.list?.map((item: any) => {
          return {
            ...item,
            value: JSON.parse(item.value)
          }
        }))
      })
    })
  }

  const handleSubmit = (e: any) => {
    if (e) {
      e.preventDefault()
    }

    const body = {
      templateId: template.cd,
      document: {
        title: `${template.title}_p${contract.partner_id}u${contract.sales_id}`,
        participantMappings: [
          // {
          //   role: '갑',
          //   name: participant1.name,
          //   signingMethod: {
          //     type: participant1.type,
          //     value: participant1.value
          //   }
          // },
          {
            role: '을',
            name: participant2.name,
            signingMethod: {
              type: participant2.type,
              value: participant2.value
            }
          }
        ]
      }
    }

    requestModuSign(body, (cb: any) => {
      setDocuments({
        ...documents,
        documents: [
          cb,
          ...documents.documents

        ]
      })


      addContract({
        ...contract,
        moduSign_id: cb.id,
        filePath: cb.file.downloadUrl,
        user_id: user.id,
        contractor_name: participant2.name
      }, (cb: any) => {

        setContractList([
          cb,
          ...contractList
        ])
      })
      // navigate(`/contract`)
    })
  }

  console.log(modal)

  const move = (currentPage: number) => {
    //alert('currentPage: ' + currentPage)
    setPageInfo({
      ...pageInfo,
      page: currentPage
    })
    getListData(currentPage, pageInfo.size)
  }

  return (
    <Container>
      <Location location={{name: '계약서'}}/>
      {
        user.role !== 1 && (
          <>
            <Content>
              <Section>
                <Select
                  id="templateType"
                  name="templateType"
                  title={'템플릿선택'}
                  size={'lg'}
                  config={
                    {
                      title: 'title',
                      value: 'value'
                    }
                  }
                  isSelected={0}
                  //탬플릿 코드 변경 완료
                  value={[
                    {
                      title: '홈스마트전당포지사계약서',
                      value: '121e8020-b36d-11ee-8466-f18ca08d204f'
                    },
                    {
                      title: '전당계약서',
                      value: '06ed37b0-b371-11ee-8466-f18ca08d204f'
                    }
                    // bonmi.corp test 모두싸인 템플릿
                    // {
                    //   title: '홈스마트전당포지사계약서',
                    //   value: '26e0bd70-b9c6-11ee-bd5c-ffe1c82f1797'
                    // }
                  ]}
                  onChange={(e) => setTemplate({
                    ...template,
                    title: e.title,
                    cd: e.value
                  })}
                  required={false}
                  readOnly={false}
                  disabled={false}
                />
              </Section>
              <Section>
                <Select
                  id="partnerType"
                  name="partnerType"
                  title={'지사 선택'}
                  size={'lg'}
                  config={
                    {
                      title: 'partner_name',
                      value: 'partner_id'
                    }
                  }
                  isSelected={contract?.partner_id}
                  value={partner}
                  onChange={(e) => {
                    setContract({
                      ...contract,
                      partner_id: e.partner_id
                    })
                  }}
                  required={true}
                  readOnly={false}
                  disabled={user.role === 2 ? true : false}
                />
                <Select
                  id="saleType"
                  name="saleTypes"
                  title={'영업자 선택'}
                  size={'lg'}
                  config={
                    {
                      title: 'user_name',
                      value: 'user_id'
                    }
                  }
                  isSelected={contract?.sales_id}
                  value={sale.length > 0 && sale.filter((el: SaleItem) => el.partner_id === contract.partner_id) || []}
                  onChange={(e) => {
                    setContract({
                      ...contract,
                      sales_id: e.user_id
                    })
                  }}
                  required={true}
                  readOnly={false}
                  disabled={user.role === 2 ? true : false}
                />
              </Section>
              <Section>
                <InputField
                  useAnimation={true}
                  type={'text'}
                  id="partnerName"
                  name="name"
                  title="계약자 명"
                  placeholder="계약자 명"
                  value={participant2.name}
                  onChange={(e) => handlePartnerChange(e)}
                />
                <Select
                  id="partnerType"
                  name="type"
                  title={'선택'}
                  size={'lg'}
                  config={
                    {
                      title: 'title',
                      value: 'value'
                    }
                  }
                  isSelected={0}
                  value={[
                    {
                      title: '이메일',
                      value: 'EMAIL'
                    },
                    {
                      title: '카카오',
                      value: 'KAKAO'
                    }
                  ]}
                  onChange={(e) => setParticipant2({
                    ...participant2,
                    type: e.value
                  })}
                  required={false}
                  readOnly={false}
                  disabled={false}
                />
                <InputField
                  useAnimation={true}
                  type={'text'}
                  id="partnerValue"
                  name="value"
                  title={participant2.type}
                  placeholder={participant2.type === 'KAKAO' ? '연락처' : '이메일주소'}
                  value={participant2.value}
                  onChange={(e) => handlePartnerChange(e)}
                />
              </Section>
            </Content>

            <Action>
              <Button
                size={'md'}
                margin={'0 1rem 2rem 0'}
                padding={'1rem 5rem'}
                radius={'.5rem'}
                bgcolor={'#3f3b3f'}
                color={'#ffffff'}
                themecolor={'dark-gray'}
                disabled={false}
                onClick={handleSubmit}
              >
                요청
              </Button>
            </Action>
          </>)
      }
      <Contract>
        {
          documents?.documents?.length > 0 ?
            <TableContainer>
              <TableWrapper>
                <StickyHeader>
                  <Table ref={tableRef}>
                    <colgroup>
                      {['15', '5', '5', '15', '5', '5', '5', '5', ''].map((cols: string, index: number) => (
                        <col key={index} style={{width: `${cols}%`, overflow: 'hidden'}}/>
                      ))}
                    </colgroup>
                    <thead>
                    <tr>
                      {['제목', '계약번호', '지사 / 영업', '참여자(을)', '계약서 작성시간', '상태', '대출금액', '유질', ''].map((item: string, index: number) => (
                        <th key={index} className={index === 0 ? 'name' : ''}>{item}</th>
                      ))}
                    </tr>
                    </thead>
                    <tbody>
                    {documents.documents.map((item: any, index: number) => {
                      //let itemMap = contractMap[item.id] ? JSON.parse(contractMap[item.id]) : {}
                      // console.log('doc.row: ', item, ' contract.row: ', itemMap)
                      let idx = contractList.findIndex((element: any) => element.key === item.id)

                      return (
                        <tr key={item.id} onClick={() => console.log(item['id'])}>
                          <td className={'first name'} style={{width: index === 0 ? '20rem' : ''}}>{item.title}</td>
                          <td>{contractList[idx]?.value?.contract_id || ''}</td>
                          <td>{contractList[idx]?.value?.partner ? `[${contractList[idx]?.value?.partner}] ${contractList[idx]?.value?.saler}` : ''}</td>
                          <td>{item['participants'][0].name}<br/>
                            {`${item['participants'][0].signingMethod.type}(${item['participants'][0].signingMethod.value})`}
                          </td>
                          {/*<td >{item['participants'][1].name}<br />*/}
                          {/*  {`${item['participants'][1].signingMethod.type}(${item['participants'][1].signingMethod.value})`}*/}
                          {/*</td>*/}
                          <td>{moment(item['updatedAt']).format('YYYY-MM-DD HH:mm:ss')}</td>
                          <td>{item['status'] === 'ON_GOING' ? '진행중' : item['status'] === 'COMPLETED' ? '완료' : '-'}</td>
                          <td>
                            <InputField
                              useAnimation={true}
                              type={'text'}
                              id={`loan_${index}`}
                              name="loan"
                              title="대출금액"
                              placeholder="대출금액"
                              value={contractList[idx]?.value?.loan || 0}
                              onChange={(e) => handlePriceChange(e, idx)}
                              disabled={user.role === 0 ? false : true}
                            />
                          </td>
                          <td>
                            <InputField
                              useAnimation={true}
                              type={'text'}
                              id={`sale_amount_${index}`}
                              name="sale_amount"
                              title="유질 매각금액"
                              placeholder="유질 매각금액"
                              value={contractList[idx]?.value?.sale_amount || 0}
                              onChange={(e) => handlePriceChange(e, idx)}
                              disabled={user.role === 0 ? false : true}
                            />
                          </td>
                          {/*<td>*/}
                          {/*  <InputField*/}
                          {/*    useAnimation={true}*/}
                          {/*    type={'text'}*/}
                          {/*    id={`deposit_amount_${index}`}*/}
                          {/*    name="deposit_amount"*/}
                          {/*    title="이자 수수료 입금 금액"*/}
                          {/*    placeholder="이자 수수료 입금 금액"*/}
                          {/*    value={contractList[idx]?.value?.deposit_amount || 0}*/}
                          {/*    onChange={(e) => handlePriceChange(e, idx)}*/}
                          {/*  />*/}
                          {/*</td>*/}
                          <td>
                            <Button
                              size={'sm'}
                              margin={'0 1rem 0 0'}
                              padding={'1rem 1.2rem'}
                              radius={'.5rem'}
                              bgcolor={'#3f3b3f'}
                              color={'#ffffff'}
                              themecolor={'blue'}
                              disabled={user.role === 2 ? true : false}
                              onClick={() => setModal(item.id)}
                            >
                              이자 수수료
                            </Button>
                            <Button
                              size={'sm'}
                              margin={'0 1rem 0 0'}
                              padding={'1rem 1.2rem'}
                              radius={'.5rem'}
                              bgcolor={'#3f3b3f'}
                              color={'#ffffff'}
                              themecolor={'blue'}
                              disabled={(idx >= 0 && contractList[idx] && user.role === 0) ? false : true}
                              onClick={() => updateContract({
                                ...contractList[idx],
                                userId: user.id
                              })}
                            >
                              저장
                            </Button>
                            <Button
                              size={'sm'}
                              margin={'0 1rem 0 0'}
                              padding={'1rem 1.2rem'}
                              radius={'.5rem'}
                              bgcolor={'#3f3b3f'}
                              color={'#ffffff'}
                              themecolor={'purple'}
                              disabled={user.role === 1 ? true : false}
                              onClick={() => {
                                window.open(item['file'].downloadUrl, 'Popup', 'width=1200,height=800')
                              }}
                            >
                              다운로드
                            </Button>
                          </td>
                        </tr>
                      )
                    })}
                    </tbody>
                  </Table>
                </StickyHeader>
              </TableWrapper>
            </TableContainer>
            : <Nodata>등록된 데이터가 없습니다.</Nodata>
        }
        <Pagination
          pageSize={pageInfo.size}
          currentPage={pageInfo.page}
          onChange={move}
          pageNumToShow={5}
          totalRecord={Math.ceil(documents.count)}
        />
      </Contract>
      {modal && <History id={modal} reset={() => setModal(null)}/>}
    </Container>
  )
}

export default Index
