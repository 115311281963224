import {atom} from 'recoil'

const accountIntAtom = atom({
  key: 'accountIntAtom',
  default: {
    list: [],
    sum: [{
      total_loan_int: 0,
      total_sale_int: 0,
      total: 0
    }]
  }
})

const accountSalesAtom = atom({
  key: 'accountSalesAtom',
  default: {
    list: [],
    sum: [{
      total_loan_interest: 0,
      total_saleAmount_interest: 0,
      total: 0
    }]
  }
})

export {
  accountIntAtom,
  accountSalesAtom
}
