import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable} from 'recoil'
import {alertAtom} from '../recoil/alert'
import {partnerAtom, partnerDetailAtom} from '../recoil/partner'

export const PartnerAction = () => {

  const navigate = useNavigate()
  const loader = useSetRecoilState(loaderAtom)
  const setAlert = useSetRecoilState(alertAtom)
  const setPartner = useSetRecoilState(partnerAtom)
  const setPartnerDetail = useSetRecoilState(partnerDetailAtom)

  const regis = (params: any) => {

    loader({
      isLoading: true
    })

    const formData = new FormData()
    formData.append('user_id', params.user_id)
    formData.append('partner_id', params.partner_id)
    formData.append('role', params.role)
    formData.append('partner_name', params.partner_name)
    formData.append('user_email', params.user_email)

    formData.append('user_password', params.user_password)
    formData.append('user_name', params.user_name)
    formData.append('user_phone', params.user_phone)
    formData.append('partner_address', params.partner_address)
    formData.append('partner_stock', params.partner_stock)
    formData.append('partner_account', params.partner_account)
    formData.append('partner_phone_0', params.partner_phone_0)
    formData.append('partner_phone_1',params.partner_phone_1)
    params.files.forEach((file: any) => {
      if(file instanceof File) {
        formData.append('file', file)
      }
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/partner/regis',
      params: formData
    }).then(
      response => {
        loader({
          isLoading: false
        })

        if(params.user_id || params.partner_id) {
          setAlert({
            title: '확인',
            type: 'alert',
            message: '수정되었습니다.',
            isShow: true
          })
          return
        }

        if(response.data.status !== 200) {
          setAlert({
            title: '오류',
            type: 'alert',
            message: '이미 등록된 지사명이거나 중복된 회원 이메일입니다.',
            isShow: true
          })
          return
        }

        navigate('/partner')
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getPartnerList = () => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/partner/getList',
      params: ''
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setPartner(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const getDetail = (params: number) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'post',
      endPoint: '/partner/getDetail',
      params: {
        id: params
      }
    }).then(
      response => {
        loader({
          isLoading: false
        })
        setPartnerDetail(response.data.payload)
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  const deletePartner = (params: any) => {

    loader({
      isLoading: true
    })

    defaultService.handleService({
      method: 'delete',
      endPoint: '/partner/deletePartner',
      params: params
    }).then(
      response => {
        loader({
          isLoading: false
        })

        if(response.data.status === 200) {
          setAlert({
            title: '확인',
            type: 'alert',
            message: '삭제되었습니다.',
            isShow: true
          })

          navigate('/partner')
        }
      },
      error => {
        loader({
          isLoading: false
        })
        setAlert({
          title: error.name,
          type: 'alert',
          message: error.message,
          isShow: true
        })
      }
    )
  }

  return {
    regis,
    getPartnerList,
    getDetail,
    deletePartner
  }
}
