import {atom} from 'recoil'
import {recoilPersist} from 'recoil-persist'

const {persistAtom} = recoilPersist({
  key: 'persist_root',
  storage: localStorage,
})

const partnerAtom = atom({
  key: 'partnerAtom',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

const partnerDetailAtom = atom({
  key: 'partnerDetailAtom',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

export {
  partnerAtom,
  partnerDetailAtom
}
