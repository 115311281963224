import {useNavigate} from 'react-router-dom'
import {defaultService} from '../service'
import {loaderAtom} from '../../store/recoil/loader'
import {useSetRecoilState, useRecoilValueLoadable} from 'recoil'
import {alertAtom} from '../recoil/alert'

export const ContractAction = () => {
    const mkey = 'bGluZWNudEBuYXZlci5jb206TXprMU1EbGlaalF0WkRjM1lTMDBZekF6TFdJeFlXRXRNVFZpT0RJMU1HUmtZelk1'
    //const mkey = 'Ym9ubWkuY29ycEBnbWFpbC5jb206T0RnM056QXdORE10TWpkaE15MDBPVFF4TFRsbE5ERXRORFEyWmpCbE1UYzFZakJp'

    const navigate = useNavigate()
    const loader = useSetRecoilState(loaderAtom)
    const setAlert = useSetRecoilState(alertAtom)

    const addContract = (params: any, cb?: any) => {
        loader({
            isLoading: true
        })
        defaultService.handleService({
            method: 'post',
            endPoint: '/contract/add',
            params: params,
        }).then(
            response => {
                loader({
                    isLoading: false
                })

                cb && cb(response.data.payload)

                setAlert({
                    title: '',
                    type: 'alert',
                    message: '정상적으로 처리되었습니다.',
                    isShow: true
                })

            },
            error => {
                loader({
                    isLoading: false
                })
                setAlert({
                    title: error.name,
                    type: 'alert',
                    message: error.message,
                     isShow: true
                })
            }
        )
    }

    const updateContract = (params: any) => {
        loader({
            isLoading: true
        })

        defaultService.handleService({
            method: 'post',
            endPoint: '/contract/update',
            params: {
                ...params,
                ...(params.value)
            },
        }).then(
            response => {
                loader({
                    isLoading: false
                })
                setAlert({
                    title: '',
                    type: 'alert',
                    message: '정상적으로 처리되었습니다.',
                    isShow: true
                })
                // navigate('/contract')
            },
            error => {
                loader({
                    isLoading: false
                })
                setAlert({
                    title: error.name,
                    type: 'alert',
                    message: error.message,
                    isShow: true
                })
            }
        )
    }

    const getContractList = (ids : any, cb?: any) => {

        loader({
            isLoading: true
        })

        defaultService.handleService({
            method: 'post',
            endPoint: '/contract/list',
            params: { moduSignIds : ids.length>0 ? ids : [''] },
        }).then(
            response => {
                loader({
                    isLoading: false
                })
                cb && cb(response.data.payload)
            },
            error => {
                loader({
                    isLoading: false
                })
                setAlert({
                    title: error.name,
                    type: 'alert',
                    message: error.message,
                    isShow: true
                })
            }
        )
    }

    const requestModuSign = (params: any, cb?: any) => {
        loader({
            isLoading: true
        })
        defaultService.handleService({
            standAlone: true,
            method: 'post',
            endPoint: 'https://api.modusign.co.kr/documents/request-with-template',
            params: params,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Basic ${mkey}`
            }
        }).then(
            response => {
                loader({
                    isLoading: false
                })

                cb && cb(response.data)
            },
            error => {
                loader({
                    isLoading: false
                })
                setAlert({
                    title: error.name,
                    type: 'alert',
                    message: error.message,
                    isShow: true
                })
            }
        )
    }

    const getModuSignList = (pageInfo: any, keyword: string ,cb?: any) => {
        loader({
            isLoading: true
        })
        console.log('in the getModuSingList: ',pageInfo)

        let filter = ''
        if(keyword.length > 0 ) {
            filter = `&filter=contains(title%2C%20'${keyword}')`
        }

        defaultService.handleService({
            standAlone: true,
            method: 'get',
            endPoint: `https://api.modusign.co.kr/documents?offset=${Number(pageInfo.size)*Number(pageInfo.page)}&limit=${pageInfo.size}` + filter ,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Basic ${mkey}`
            }
        }).then(
            response => {
                console.log('moduSign: ', response)
                //total: response.data.count
                loader({
                    isLoading: false
                })
                cb && cb(response.data)
            },
            error => {
                loader({
                    isLoading: false
                })
                setAlert({
                    title: error.name,
                    type: 'alert',
                    message: error.message,
                    isShow: true
                })
            }
        )
    }

    const getDeposit = async (params: any) => {
        loader({
            isLoading: true
        })
        return await defaultService.handleService({
            method: 'post',
            endPoint: '/contract/getDeposit',
            params: params,
        }).then(
          response => {
              loader({
                  isLoading: false
              })

              return response.data.payload
          },
          error => {
              loader({
                  isLoading: false
              })
              setAlert({
                  title: error.name,
                  type: 'alert',
                  message: error.message,
                  isShow: true
              })
          }
        )
    }

    const regisAmount = async (params: any) => {
        loader({
            isLoading: true
        })
        return await defaultService.handleService({
            method: 'post',
            endPoint: '/contract/regisAmount',
            params: params,
        }).then(
          response => {
              loader({
                  isLoading: false
              })

              setAlert({
                  title: '',
                  type: 'alert',
                  message: '정상적으로 처리되었습니다.',
                  isShow: true
              })

              return response.data.payload
          },
          error => {
              loader({
                  isLoading: false
              })
              setAlert({
                  title: error.name,
                  type: 'alert',
                  message: error.message,
                  isShow: true
              })
          }
        )
    }

    return {
        addContract,
        updateContract,
        getContractList,
        requestModuSign,
        getModuSignList,
        getDeposit,
        regisAmount
    }
}
