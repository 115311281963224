import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {useRecoilValue} from 'recoil'

import {AuthAction} from '../../store/action'
import {authAtom} from '../../store/recoil/auth'

const Container = styled.div`
  display: flex;
  min-height: 6.7rem;
  align-items: center;
  justify-content: center;
  border-bottom: .1rem solid #efebef;
`

const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  //max-width: 140rem;
`

const Logo = styled.div`
  display: flex;
  width: 10rem;
  height: 4rem;
  //background-image: url('../../../images/logo.png');
  background-size: 4rem;
  background-repeat: no-repeat;
  background-position: 0rem center;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
`

const User = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const UserName = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
`

const UserEmail = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: #646464;
`

const UserImage = styled.img.attrs((props) => ({src: props.src,}))`
  border-radius: 3.2rem;
  width: 3.2rem;
  margin-right: 1rem;
`

const SubLayer = styled.div`
  position: absolute;
  left: 0;
  bottom: -4rem;
  width: 100%;
  background: #ffffff;
  border: .1rem solid #efebef;
  border-radius: .5rem;
  padding: 1rem;
  text-align: center;
  z-index: 9999;

  button {
    width: 100%;
    font-size: 1.4rem;
  }
`

const dummy = {
  rank: 1,
  high: '15.5%',
  low: '-20%',
  user: {
    image: '/images/avatar.jpg'
  }
}

const Index = () => {

  const auth = useRecoilValue(authAtom)
  const {authLogout} = AuthAction()
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  return (
    <Container>
      <Header>
        <Link to={'/'}>
          <Logo>홈스마트 전당포</Logo>
        </Link>
        <User
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <UserImage
            src={dummy.user.image}
          />
          <UserInfo>
            <UserName>
              {auth.name}
            </UserName>
            <UserEmail>
              {auth.email}
            </UserEmail>
          </UserInfo>
          {
            isHovering &&
              <SubLayer>
                  <button
                      onClick={() => authLogout(auth.token.jwt)}
                  >
                      로그아웃
                  </button>
              </SubLayer>
          }
        </User>
      </Header>
    </Container>
  )
};

export default Index
