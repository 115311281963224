import {atom} from 'recoil'

interface AlertProps {
  contract_int?: string
  int_head_int?: string
  int_branch_int?: string
  int_sale_int?: string
  sale_head_int?: string
  sale_branch_int?: string
  sale_sale_int?: string
}

export const settingAtom = atom<AlertProps>({
  key: 'settingAtom',
  default: {
    contract_int: '0',
    int_head_int: '0',
    int_branch_int: '0',
    int_sale_int: '0',
    sale_head_int: '0',
    sale_branch_int: '0',
    sale_sale_int: '0',
  }
})

