/************************
 // 사옹볍 (게시판 모듈)
 import Pagination from '../../../module/pagination'

 const [currentPage, setCurrentPage] = useState<number>(0)
 const pageSize: number = 5

 useEffect(() => {
    const page = pageNum ? Number(pageNum) - 1 : 0

    getPost({
      id: 0,
      parent: 1,
      page: page * pageSize,
      count: pageSize
    })
    setCurrentPage(page)
  }, [pageNum])

 <Pagination
 // 전체 데이터 갯수
 totalRecord={post.totalCount}
 // 보여질 목록의 갯수
 pageSize={pageSize}
 // 현재 페이지 번호
 currentPage={currentPage}
 onChange={(page) => setPage(page)}
 />
 ****************************/

import React, {useState, useEffect} from 'react'
import {Style} from './Style'
import Svg from '../svg/'

interface Props {
  totalRecord: number
  pageSize: number
  pageNumToShow?: number
  currentPage: number
  onChange: (page: number) => void
}

const Index = ({totalRecord, pageSize, pageNumToShow, currentPage, onChange}: Props) => {
  const totalPages: number = Math.ceil(totalRecord / pageSize)
  const maxPagesToShow: number = pageNumToShow ? pageNumToShow : 10

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowLeft') {
      handlePreviousPage()
    } else if (event.key === 'ArrowRight') {
      handleNextPage()
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown])

  const handlePageChange = (page: number) => {
    onChange(page)
  }

  let startPage: number,
    endPage: number

  if (totalPages <= maxPagesToShow) {
    startPage = 0
    endPage = totalPages - 1
  } else {
    const halfPagesToShow: number = Math.floor(maxPagesToShow / 2)
    if (currentPage <= halfPagesToShow) {
      startPage = 0
      endPage = maxPagesToShow - 1
    } else if (currentPage + halfPagesToShow >= totalPages) {
      startPage = totalPages - maxPagesToShow + 1
      endPage = maxPagesToShow - 1
    } else {
      startPage = currentPage - halfPagesToShow
      endPage = currentPage + halfPagesToShow
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      handlePageChange(currentPage - 1)
    }
  }

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      handlePageChange(currentPage + 1)
    }
  }

  return (
    <Style.Container>
      {endPage > 0 &&
        <Style.Button
          className="prev-btn"
          onClick={handlePreviousPage}
          disabled={currentPage === 0}
        >
          <Svg
            icon="arrow"
            width={12}
            height={12}
            color="#666666"
          />
        </Style.Button>
      }

      <Style.Pages>
        {Array.from({length: endPage - startPage + 1}, (_, index) => (
          <Style.PageItem
            key={startPage + index}
          >
            <Style.Button
              type="button"
              className={currentPage === startPage + index ? 'active' : ''}
              onClick={() => handlePageChange(startPage + index)}
            >
              {startPage + index + 1}
            </Style.Button>
          </Style.PageItem>
        ))}
      </Style.Pages>

      {endPage > 0 &&
        <Style.Button
          className="next-btn"
          onClick={handleNextPage}
          disabled={currentPage === totalPages - 1}
        >
          <Svg
            icon="arrow"
            width={12}
            height={12}
            color="#666666"
          />
        </Style.Button>
      }
    </Style.Container>
  )
}

export default Index
