import React, {useState, useEffect, useRef} from 'react'
import Location from '../module/location'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'
import styled from 'styled-components'
import {AdminAction} from '../store/action/admin'
import {dashboardAtom} from '../store/recoil/dashboard'
import {useRecoilValue} from 'recoil'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  gap: 2rem;
  margin-top: 1rem;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`

const Section = styled.div`
  display: flex;
  flex: 1;
  border: 0.1rem solid #efebef;
  border-radius: 2rem;
  padding: 1.5rem;
  background: #f9f9f9;

  &:first-child {
    min-width: 37%;
    background: #ffffff;
  }
`

const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 1.6rem;
  font-weight: 600;
`

const Notice = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
`

const List = styled.ul`
  display: flex;
  flex: 1;
  align-self: stretch;
  flex-direction: column;
  margin-top: 1.5rem;
`

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  margin: .8rem 0 0 0;
  font-size: 1.5rem;
  font-weight: 500;
  cursor: pointer;
`

const Date = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 1rem;
  font-size: 1.4rem;
  font-weight: 500;
`

const Nodata = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  color: #888888;
`

const Index = () => {
  const navigate = useNavigate()
  const notice = useRecoilValue(dashboardAtom)
  const {getNotice} = AdminAction()

  useEffect(() => {
    getNotice()
  }, [])

  return (
    <Container>
      <Location location={{name: 'DashBoard'}}/>
      <Content>
        <Section>
          <Notice>
            <Title onClick={() => navigate('/board')}>공지사항</Title>
            <List>
              {notice && notice.notice ? notice.notice.map((item: any) => {
                return (
                  <ListItem
                    key={item.id}
                    onClick={() => navigate('/board/view/' + item.id)}
                  >
                    {item.subject}
                    <Date>{moment(item.updatedAt).format('YYYY.MM.DD')}</Date>
                  </ListItem>
                )
              }) : <Nodata>등록된 공지사항이 없습니다.</Nodata>}
            </List>
          </Notice>
        </Section>
      </Content>
    </Container>
  )
}

export default Index
