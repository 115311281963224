import {atom} from 'recoil'
import {recoilPersist} from 'recoil-persist'

const {persistAtom} = recoilPersist({
  key: 'persist_root',
  storage: localStorage,
})

interface NoticeList {
  totalCount: number,
  notice: {
    id: number
    subject: string
    content: string
    created_at: string
    updated_at: string
    userId: number
  }[]
}

interface Notice {
  id?: number
  subject: string
  content: string
  name: string
  email: string
  created_at: string
}

export const tempIdAtom = atom({
  key: 'tempIdAtom',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

export const noticeListAtom = atom<NoticeList>({
  key: 'noticeListAtom',
  default: {
    notice: [],
    totalCount: 0
  }
})

export const noticeAtom = atom<Notice>({
  key: 'noticeAtom',
  default: {
    id: 0,
    subject: '',
    content: '',
    name: '',
    email: '',
    created_at: ''
  }
})


