import {ImageContent, ImageLoadSpinner, ImageModuleWrap} from './style'
import React, {useEffect, useState} from "react";
import {ImageModuleType} from "./interface";

const Index = ({
                 src,
                 width,
                 height,
                 radius
               }: ImageModuleType) => {

  const [url, setUrl] = useState<string>('')

  useEffect(() => {
    fetch(src)
      .then((res) => res.blob())
      .then((res) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(res)
        fileReader.onload = (data) => {
          const result = data.target?.result || src
          setUrl(result.toString())
        }
      })
  }, [src])

  return <ImageModuleWrap
    width={width}
    height={height}
    radius={radius}
  >
    {
      url ?
        <ImageContent src={url}/> :
        <ImageLoadSpinner/>
    }
  </ImageModuleWrap>

}

export default Index
