import React, {useState} from 'react'
import 'react-dates/initialize'
import {DateRangePicker} from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import "moment/locale/ko"

import {Style} from './Style'

interface Moment {
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
}

const Index = (props: any) => {

  const [startDate, setStartDate] = useState<moment.Moment | null>(props.startDate ? props.startDate : moment())
  const [endDate, setEndDate] = useState<moment.Moment | null>(props.endDate ? props.endDate : moment())
  const [focusedInput, setFocusedInput] = useState<any>(null)
  moment.locale('ko')

  const handleDatesChange = ({startDate, endDate}: Moment) => {
    setStartDate(startDate)
    setEndDate(endDate)

    props.onChange({
      start: startDate,
      end: endDate
    })
  }
  const isOutsideRange = () => false

  return (
    <>
      <DateRangePicker
        startDate={startDate}
        startDateId="start_date"
        endDate={endDate}
        endDateId="end_date"
        onDatesChange={({startDate, endDate}) => handleDatesChange({startDate, endDate})}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
        isOutsideRange={isOutsideRange}
      />
      <Style />
    </>
  )
}

export default Index
