import React, {useState, useEffect} from 'react'
import {Style} from './Style'
import Svg from '../../module/svg'
import {FileAction} from './action/index'
import {alertAtom} from '../../store/recoil/alert'
import {useSetRecoilState} from 'recoil'

const File = (props) => {
  const setAlert = useSetRecoilState(alertAtom)
  const {deleteFile} = FileAction()
  const [files, setFiles] = useState([])

  const setFile = (e, type) => {
    const fileList = e.target.files

    if (fileList.length > 0) {
      const fileArray = Array.from(fileList).map(file => ({
        name: file.name,
        thumbnail: URL.createObjectURL(file)
      }))
      setFiles({
        ...fileList, fileArray
      })
      props.onChange({
        files: fileList
      })
    }
  }

  const handleDelete = (e, file) => {
    e.preventDefault()
    deleteFile({
      id: file.id,
    }, () => {
      const deletedFiles = files.filter((item) => item.id !== file.id)
      setFiles(deletedFiles)
    })
  }

  const handleSetImage = (e, file) => {
    e.preventDefault()
    props.setImage(file)
  }

  useEffect(() => {
    if (props.thumbnail) {
      const fileArray = props.thumbnail.map(file => ({
        id: file.id,
        name: file.name,
        thumbnail: file.path ? `${process.env.REACT_APP_IMAGE_URL + file.path}` : URL.createObjectURL(file)
      }))
      setFiles(fileArray)
    }
  }, [props.thumbnail])

  return (
      <Style.Container>
        <Style.Content>
          <Style.Input
              type="file"
              id={props.name}
              name={props.name}
              placeholder={props.placeholder}
              accept={props.accept}
              multiple
              onChange={(e) => setFile(e, props.name)}
          />
          <Style.Label htmlFor={props.name}>
            {files.length > 0 ? (
                files.map((file, index) => (
                    <Style.ImgContainer
                        key={index}
                        width={props.width}
                        height={props.height}
                        radius={props.radius}
                        onClick={(e) => handleSetImage(e, file.thumbnail)}
                    >
                      <Style.Img
                          src={file.thumbnail}
                          alt="thumbnail"
                          border={props.border}
                      />
                      <Style.Icon
                          onClick={(e) => [
                            e.preventDefault(),
                            e.stopPropagation(),
                            setAlert({
                              title: '확인',
                              type: 'confirm',
                              message: '삭제하시겠습니까?',
                              isShow: true,
                              action: {
                                method: () => {
                                  handleDelete(e, file)
                                }
                              }
                          })]}
                      >
                        <Svg
                            icon = 'closeRound'
                            width = {30}
                            height = {30}
                            color={'#666666'}
                            stroke={'none'}
                            strokeWidth={1}
                            strokeCap={'round'}
                            strokeJoin={'round'}
                        />
                      </Style.Icon>
                    </Style.ImgContainer>
                ))
            ) : (
                <Style.Text>
                  {props.placeholder}
                </Style.Text>
            )}
            {
              files.length > 0 &&
                <Style.Add
                  width={props.width}
                  height={props.height}
                  radius={props.radius}
                  htmlFor={props.name}
                >
                  <Style.Icon>
                    <Svg
                        icon = 'plus'
                        width = {24}
                        height = {24}
                        color={'#666666'}
                        stroke={'none'}
                        strokeWidth={6}
                        strokeCap={'round'}
                        strokeJoin={'round'}
                    />
                  </Style.Icon>
                </Style.Add>
            }
          </Style.Label>
        </Style.Content>
      </Style.Container>
  )
}

export default File
