import styled from "styled-components";
import {ImageModuleSizeStyleInterface, ImageModuleStyleInterface} from "./interface";

export const ImageModuleWrap = styled.div<ImageModuleSizeStyleInterface>`
  position: relative;
  width: ${p => p.width};
  height: ${p => p.height};
  background-color: var(--color-border-basic);
  overflow: hidden;
  ${p => p.radius && `border-radius:${p.radius};`}
`
export const ImageContent = styled.div<ImageModuleStyleInterface>`
  position: absolute;
  top: 0;
  left: 0;
  background: center center url("${p => p.src}");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`

export const ImageLoadSpinner = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 4rem;
  height: 4rem;
  border: .5rem solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }
    100% {
      transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
  }
`
