import React, {useState, useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import Location from '../../module/location'
import Board from '../../module/board'
import Pagination from '../../module/pagination'

import {useRecoilValue, useSetRecoilState} from 'recoil'
import {salesAtom} from '../../store/recoil/sales'
import {SalesAction} from '../../store/action'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 9.7rem;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 2rem 0;
`

const Index = () => {

  useEffect(() => {

  }, [])

  return (
    <Container>
      <Board />
    </Container>
  )
}

export default Index
