import React, {useState, useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import Location from '../../module/location'
// import Table from '../../module/table'
import Pagination from '../../module/pagination'
import moment from 'moment'

import {useRecoilValue, useSetRecoilState} from 'recoil'
import {PartnerAction} from '../../store/action'
import {partnerAtom} from '../../store/recoil/partner'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 9.7rem;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 2rem 0;
`

const Nodata = styled.div`
  display: flex;
  flex: 1;
  font-size: 1.3rem;
  font-weight: 400;
  color: #666666;
`

const Table = styled.table`
  border-radius: 1rem;
  overflow: hidden;
  border-collapse: collapse;
  text-align: left;

  thead {
    border-bottom: .1rem solid #efebef;
  }

  tr: hover {
    background: #fdfdfd;
    cursor: pointer;
  } thead th {
    padding: 1.4rem 1.4rem;
  } th, td {
    font-size: 1.4rem;
    font-weight: 400;
    color: #9e9e9e;
    border-bottom: .1rem solid #efebef;
    white-space: nowrap;
    padding: 1.4rem 1.4rem;
  }
`

const Index = () => {

  const navigate = useNavigate()
  const partner = useRecoilValue(partnerAtom)
  const { getPartnerList } = PartnerAction()

  useEffect(() => {
    getPartnerList()
  }, [])

  return (
    <Container>
      <Location location={{name: '지사 관리'}}/>

      <Content>
        <Table>
          <colgroup>
            <col width={'2%'} />
            <col width={'6%'} />
            <col width={'6'} />
            <col width={'10%'} />
            <col width={''} />
          </colgroup>

          <thead>
          <tr>
            <th>ID</th>
            <th>지사명</th>
            <th>이름</th>
            <th>연락처</th>
            <th>마지막 접속시간</th>
          </tr>
          </thead>

          <tbody>
          {partner.length > 0 && partner.map((item: any, index: number) => {
            return (
              <tr key={index} onClick={() => navigate(`/partner/regis/${item.partner_id}`)}>
                <td>{item.partner_id}</td>
                <td>{item.partner_name}</td>
                <td>{item.user_name}</td>
                <td>{item.user_phone}</td>
                <td>{item.created_at ? moment(item.created_at).format('YYYY-MM-DD : HH:MM') : '-'}</td>
              </tr>
            )
          })}
          </tbody>
        </Table>

        <Pagination
          totalRecord={10}
          pageSize={10}
          currentPage={0}
          onChange={(page) => navigate(`/notice/${(page + 1)}`)}
        />
      </Content>
    </Container>
  )
}

export default Index
