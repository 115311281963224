import React, { useState, useEffect, lazy, Suspense } from 'react'
import {Routes, Route} from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 1rem;
  padding-bottom: 1rem;
`

const Board = styled.div`
  display: flex;
  flex: unset;
  flex-direction: column;
`

const Index = (props: any) => {

  const { config } = props

  const List  = lazy(() => import('./default/List'))
  const View  = lazy(() => import('./default/View'))
  const Write = lazy(() => import('./default/Write'))

  return (
    <Container>
      <Board>
        <Suspense fallback={''}>
          <Routes>
            {['/', '/:pageNum'].map((path, index) => (
              <Route
                key={index}
                path={path}
                element={<List config={config} />}
              />
            ))}

            <Route
              path="/view/:id"
              element={<View config={config} />}
            />

            {['/write', '/write/:id'].map((path, index) => (
              <Route
                key={index}
                path={path}
                element={<Write config={config} />}
              />
            ))}
          </Routes>
        </Suspense>
      </Board>
    </Container>
  )
}

export default Index
