import React, {useState, useEffect, useCallback} from 'react'
import {useParams} from 'react-router'
import styled from 'styled-components'
import InputField from '../../module/input'
import Button from '../../module/button'
import Pagination from '../../module/pagination'
import {useRecoilValue} from 'recoil'
import {alertAtom} from '../../store/recoil/alert'
import {authAtom} from '../../store/recoil/auth'
import {ContractAction} from '../../store/action'
import moment from 'moment'
import {useNavigate} from 'react-router-dom'
import {useSetRecoilState} from 'recoil'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999999999;
  width: 100%;
  height: 100%;
  z-index: 9;
`

const Modal = styled.div`
  box-sizing: border-box;
  height: auto;
  width: 30%;
  //max-width: 40rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  font-size: 1.2rem;
  background: #ffffff;
  border-radius: 1rem;
  padding: 1rem 2rem;

  @media (max-width: 968px) {
    width: 90%;
  }
`

const Section = styled.div`
  display: flex;
  flex: 1;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  max-width: 12rem;
  align-self: stretch;
  flex-grow: 1;
  align-items: stretch;
  margin: 0 0 1rem 0;

  @media (max-width: 1008px) {
    max-width: 100%;
  }
`

const Table = styled.table`
  width: 100%;
  border-radius: 1rem;
  border-collapse: collapse;
  overflow: hidden;
  margin-top: 0;

  thead {
    font-size: 1.3rem;
    text-align: left;
  }

  thead th {
    font-weight: 400;
    color: #81858c;
    background: #f3f5f7;
    padding: 1.4rem 1.2rem;

    &.text-right {
      text-align: right
    }
  }

  th, td {
    padding: 1rem;
    white-space: nowrap;
  }

  td {
    font-size: 1.4rem;
    font-weight: 350;
    color: #777777;
    border: 0;
    border-bottom: .1rem solid #efebef;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 1.2rem;
    cursor: pointer;

    &.text-right {
      text-align: right
    }

    &.text-center {
      text-align: center
    }
  }
`

const Title = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  font-size: 1.5rem;
  font-weight: 600;
`

const Action = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

const Index = (props: any) => {

  const {id} = useParams()
  const navigate = useNavigate()
  const [state, setState] = useState<any>({
    deposit_amount: 0
  })
  const setAlert = useSetRecoilState(alertAtom)
  const [pageInfo, setPageInfo] = useState<any>({
    size: 5,
    page: 0
  })
  const {getDeposit, regisAmount} = ContractAction()

  const handleChange = useCallback((e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }, [state])

  const handleSubmit = (e: any) => {
    if (e) {
      e.preventDefault()
    }

    const {deposit_amount} = state

    if (!deposit_amount) {
      setAlert({
        title: '확인',
        type: 'alert',
        message: `이자 수수료 금액을 입력하세요`,
        isShow: true
      })
      return
    }

    regisAmount({
      id: props.id,
      amount: deposit_amount
    }).then(() => {
      setState({
        ...state,
        data: [
          ...state.data,
          {
            deposit_amount: deposit_amount,
            createdAt: moment()
          }
        ]
      })
    })
  }

  useEffect(() => {
    getDeposit({
      id: props.id,
      page: 0,
      size: 10
    }).then((data) => {
      setState({
        ...state,
        data: data
      })
    })
  }, [])

  return (
    <Container>
      <Modal>
        <Title>이자 수수료 입금액</Title>
        <Section>
          <InputField
            useAnimation={true}
            type={'text'}
            id="deposit_amount"
            name="deposit_amount"
            title="이자 수수료 입금 금액"
            placeholder="이자 수수료 입금 금액"
            value={state.deposit_amount}
            onChange={(e) => handleChange(e)}
          />

          <Button
            size={'sm'}
            margin={'.8rem 0 .8rem 0'}
            padding={'1rem 1.2rem'}
            radius={'.5rem'}
            bgcolor={'#3f3b3f'}
            color={'#ffffff'}
            themecolor={'blue'}
            disabled={false}
            // onClick={handleSubmit}
            onClick={() => {
                setAlert({
                  title: '확인',
                  type: 'confirm',
                  message: `이자 수수료 금액을 등록하시겠습니까?`,
                  isShow: true,
                  action: {
                    method: handleSubmit
                  }
                })
                return
              }}
          >
            등록
          </Button>
        </Section>

        <Table>
          <colgroup>
            <col/>
            <col width="120"/>
            {/*<col width="120"/>*/}
          </colgroup>
          <thead>
          <tr>
            <th>입금 금액</th>
            <th>등록일</th>
            {/*<th>등록일</th>*/}
          </tr>
          </thead>
          <tbody>
          {state.data && state.data.length > 0 ? state.data.map((item: any, index: number) => {
            return (
              <tr key={index}>
                <td>{item.deposit_amount.toLocaleString()}</td>
                <td>{moment().format('YYYY.MM.DD')}</td>
              </tr>
            )
          }): <tr><td colSpan={2}>입력한 데이터가 없습니다.</td></tr>}
          </tbody>
        </Table>

        {/*<Pagination*/}
        {/*  pageSize={pageInfo.size}*/}
        {/*  currentPage={pageInfo.page}*/}
        {/*  onChange={move}*/}
        {/*  pageNumToShow={5}*/}
        {/*  totalRecord={Math.ceil(documents.count)}*/}
        {/*/>*/}

        <Action>
          <Button
            size={'md'}
            margin={'1rem 0 2rem 0'}
            padding={'1rem 2rem 1rem 2rem'}
            radius={'.5rem'}
            bgcolor={'#3f3b3f'}
            color={'#ffffff'}
            themecolor={'dark-gray'}
            disabled={false}
            onClick={() => props.reset()}
          >
            확인
          </Button>
        </Action>
      </Modal>
    </Container>
  )
}

export default Index
