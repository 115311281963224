import React, {useState, useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import Location from '../../../module/location'
import moment from 'moment'

import {useRecoilValue, useSetRecoilState} from 'recoil'
import {accountSalesAtom} from '../../../store/recoil/account'
import {AccountAction} from '../../../store/action'
import Pagination from '../../../module/pagination';
import Calendar from '../../../module/calendar'
import Button from '../../../module/button'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 9.7rem;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 2rem 0;
`

const Nodata = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 400;
  color: #666666;
`

const Filter = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  margin: 2rem 0;

  .DateRangePicker {
    flex: 1;
  }
`

const Table = styled.table`
  border-radius: 1rem;
  overflow: hidden;
  border-collapse: collapse;
  text-align: left;
  
  thead {
    border-bottom: .1rem solid #efebef;
  }

  tr: hover {
    background: #fdfdfd;
    cursor: pointer;
  }

  tr.total {
    background: #f8f8f8;
    cursor: pointer;
    td {
      font-weight: bold;
      border-bottom: 0;
      
      .sum-title {
        font-size: 1.6rem;
        margin-bottom: 1rem;
        color: #333333;
        margin-right: 2rem;
      }

      .sum-content {
        display: flex;
        font-size: 1.6rem;
        margin-bottom: .4rem;
        color: #333333;
        min-width: 10rem;
        justify-content: flex-end;
      }

      .sum-price {
        display: flex;
        font-size: 1.6rem;
        margin-bottom: .4rem;
        color: #333333;
        min-width: 7rem;
        justify-content: flex-end;
      }

      .sum-title {
        width: 5rem;
        font-size: 1.6rem;
        margin-bottom: .4rem;
        color: #333333;
        display: inline-block;
      }

      .sum-total {
        display: flex;
        justify-content: flex-end;
        font-size: 1.6rem;
        margin-bottom: .4rem;
        color: #333333;
      }
    }
  } thead th {
    padding: 1.4rem 1.4rem;
  } th, td {
    font-size: 1.4rem;
    font-weight: 400;
    color: #9e9e9e;
    border-bottom: .1rem solid #efebef;
    white-space: nowrap;
    padding: 1.4rem 1.4rem;
  }
`

const Index = () => {

  const navigate = useNavigate()
  const {getSalesList, getSalesExcel} = AccountAction()
  const list = useRecoilValue(accountSalesAtom)
  const [state, setState] = useState<any>({
    date: {
      start: moment().subtract(1, 'weeks').startOf('isoWeek'),
      end: moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD 23:59:59')
    }
  })

  const handleCalender = (value: any) => {
    setState({
      ...state,
      date: {
        start: value.start || value.end,
        end: value.end || value.start
      }
    })
  }

  useEffect(() => {
    getSalesList({
      from: moment(state.date.start).format('YYYY-MM-DD 00:00:00'),
      to: moment(state.date.end).format('YYYY-MM-DD 23:59:59')
    })
  }, [state.date])

  return (
    <Container>
      <Location location={{name: '영업자 정산 관리'}} />

      <Filter>
        <Calendar
          startDate={moment().subtract(1, 'weeks').startOf('isoWeek')}
          endDate={moment().subtract(1, 'weeks').endOf('isoWeek')}
          onChange={(value: any) => handleCalender(value)}
        />

        <Button
          size={'md'}
          margin={'0 0 0 1rem'}
          padding={'1rem 5rem'}
          radius={'.5rem'}
          bgcolor={'#3f3b3f'}
          color={'#ffffff'}
          themecolor={'dark-gray'}
          disabled={false}
          onClick={() => getSalesExcel({
            from: state.date.start,
            to: state.date.end
          })}
        >
          엑셀로 저장
        </Button>
      </Filter>

      <Table>
        <colgroup>
          <col width={'6%'} />
          <col width={'6%'} />
          <col width={'6%'} />
          <col width={'6%'} />
          <col width={''} />
          <col width={'6%'} />
          <col width={'6%'} />
          <col width={'6%'} />
          <col width={'6%'} />
          <col width={'6%'} />
          <col width={'6%'} />
        </colgroup>

        <thead>
        <tr>
          <th>계약번호</th>
          <th>상위지사명</th>
          <th>영업자명</th>
          <th>계약일자</th>
          <th>계약자명</th>
          <th>대출금액</th>
          <th>유질매각금액</th>
          <th>이자 수수료율</th>
          <th>유질 수수료</th>
          <th>이자 수수료</th>
          <th>계좌정보</th>
        </tr>
        </thead>

        <tbody>
        {list.list.length > 0 ? list.list.map((item: any, index: number) => {
          return (
            <tr key={index}>
              <td>{item.id}</td>
              <td>{item.partner}</td>
              <td>{item.saler}</td>
              <td>{moment(item.createdAt).format('YYYY-MM-DD')}</td>
              <td>{item.contractor_name}</td>
              <td>{item.loan?.toLocaleString()}</td>
              <td>{item.sale_amount?.toLocaleString()}</td>
              <td>{`${item.int_rate}%`}</td>
              <td>{item.saleAmount_interest?.toLocaleString()}</td>
              <td>{item.loan_interest?.toLocaleString()}</td>
              <td>{item.account}</td>
            </tr>
          )
        }) : <tr><td colSpan={11} style={{borderBottom: '0'}}><Nodata>검색된 데이터가 없습니다.</Nodata></td></tr>}
        {list.sum[0].total_saleAmount_interest &&
        <tr className={'total'}>
          <td colSpan={8}>
            <div className={''}>
              <div className={'sum-title'}>지급 수수료 합계</div>
            </div>
          </td>
          <td style={{textAlign: 'right'}} colSpan={3}>
            <div className={'sum-content'}>
              <div className={'sum-title'}>유질</div>
              <div className={'sum-price'}>{list.sum[0].total_saleAmount_interest?.toLocaleString()}</div>
            </div>
            <div className={'sum-content'}>
              <div className={'sum-title'}>이자</div>
              <div className={'sum-price'}>{list.sum[0].total_loan_interest?.toLocaleString()}</div>
            </div>
            <div className={'sum-total'}>
              <div className={'sum-title'}>합계</div>
              <div className={'sum-price'}>{list.sum[0].total?.toLocaleString()}</div>
            </div>
          </td>
        </tr>}
        </tbody>
      </Table>

      <Pagination
        totalRecord={10}
        pageSize={10}
        currentPage={0}
        onChange={(page) => console.log('-')}
      />
    </Container>
  )
}

export default Index
