import {atom} from 'recoil'

const dashboardAtom = atom({
  key: 'dashboardAtom',
  default: {
    notice: [],
    totalCunt: 0
  }
})

export {dashboardAtom}
