import React from 'react'
import {Routes, Route} from 'react-router-dom'

import Login from './Login'
import Join from './Join'
import FindId from './FindEmail'
import FindPass from './FindPass'
import NotFound from '../../page/NotFound'

const Index = () => {

	const authRoutes = [
		{ name: 'Login', path: '/*', element: Login },
		{ name: 'Join', path: '/join', element: Join },
		{ name: 'FindId', path: '/find/email', element: FindId },
		{ name: 'FindPass', path: '/find/pass', element: FindPass },
		{ name: '404', path: '*', element: NotFound },
	]

  return (
		<Routes>
			{authRoutes.map((route, key) => (
				<Route
					key={key}
					path={route.path}
					element={<route.element />}
				/>
			))}
		</Routes>
	)
}
export default Index
