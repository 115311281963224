import React, {useState, useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import Location from '../../../module/location'
import Table from '../../../module/table'

import {useRecoilValue, useSetRecoilState} from 'recoil'
import {salesAtom} from '../../../store/recoil/sales'
import {SalesAction} from '../../../store/action'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 9.7rem;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 2rem 0;
`

const Nodata = styled.div`
  display: flex;
  flex: 1;
  font-size: 1.3rem;
  font-weight: 400;
  color: #666666;
`

const Index = () => {

  useEffect(() => {

  }, [])

  return (
    <Container>
      <Location location={{name: '엽업자 정산 관리'}} />
      <Content>
        detail
      </Content>
    </Container>
  )
}

export default Index
