import React from 'react'
import {
  BrowserRouter,
  unstable_HistoryRouter as HistoryRouter,
  Route,
  Routes
} from 'react-router-dom'
import ScrollToTop from './ScrollToTop'

import {useRecoilState, useRecoilValue} from 'recoil'
import {authAtom} from '../store/recoil/auth'

/*************
 LayOut
 *************/

import DefaultLayout from '../layout/DefaultLayout'

/*************
 Auth
 *************/

import AuthRoute from './AuthRoute'

/*************
 public
 *************/

import Auth from '../page/auth'
import NotFound from '../page/NotFound'

/*************
 auth
 *************/

import Index from '../page/'
import Partner from '../page/partner'
import PartnerRegis from '../page/partner/Regis'
import Sales from '../page/sales'
import SalesRegis from '../page/sales/Regis'

import Setting from '../page/setting/'
import Board from '../page/board/'
import Pds from '../page/pds/'

import Contract from '../page/contract/'
import ContractHistory from '../page/contract/History'

import Account from '../page/account/'
import AccountDetail from '../page/account/Detail'

import Sale from '../page/account/sale/'
import SaleSDetail from '../page/account/sale/Detail'

const Routers = () => {

  const auth = useRecoilValue(authAtom)

  const publicRoutes = [
    {name: 'Auth', path: '/auth/*', element: Auth},
    {name: '404', path: '/*', element: NotFound},
  ]

  const authRoutes = [
    {name: 'Index', path: '/', element: Index},

    {name: 'Partner', path: '/partner/', element: Partner},
    {name: 'PartnerRegis', path: '/partner/regis/', element: PartnerRegis},
    {name: 'PartnerRegis', path: '/partner/regis/:id', element: PartnerRegis},

    {name: 'Sales', path: '/sales/*', element: Sales},
    {name: 'SalesRegis', path: '/sales/regis/', element: SalesRegis},
    {name: 'SalesRegis', path: '/sales/regis/:id', element: SalesRegis},

    {name: 'Contract', path: '/contract/', element: Contract},
    {name: 'Contract', path: '/contract/:page', element: Contract},
    {name: 'ContractHistory', path: '/contract/history/:page', element: ContractHistory},

    {name: 'Setting', path: '/setting', element: Setting},
    {name: 'Board', path: '/board', element: Board},
    {name: 'Pds', path: '/pds', element: Pds},

    {name: 'Account', path: '/account/int', element: Account},
    {name: 'Account', path: '/account/int/:id', element: AccountDetail},

    {name: 'Sale', path: '/account/sales', element: Sale},
    {name: 'SaleDetail', path: '/account/sales/:id', element: SaleSDetail},

    {name: 'Board', path: '/board/*', element: Board},
  ]

  return (
    <Routes>
      <Route
        path="/" element={
        <AuthRoute auth={auth}>
          <DefaultLayout />
        </AuthRoute>
      }
      >
        {authRoutes.map((route, key) => (
          <Route
            key={key}
            path={route.path}
            element={<route.element />}
          />
        ))}
      </Route>

      {publicRoutes.map((route, key) => (
        <Route
          key={key}
          path={route.path}
          element={<route.element/>}
        />
      ))}
    </Routes>
  )
}

export default Routers
